
















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import InputAutosize from '@/shared/resources/directives/InputAutosize';

@Component({
  directives: {
    autowidth: InputAutosize,
  },
})
export default class TableTextField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: string | number;
  @Prop({ default: false, type: Boolean }) private readonly!: boolean;
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: false, type: Boolean }) private fullWidth!: boolean;

  /**
   * Getters
   */
  private get classNames(): object {
    return {
      'table-text-field--small': this.small,
      'table-text-field--full-width': this.fullWidth,
    };
  }

  /**
   * Handlers
   */
  private onInputFocus(event: Event) {
    if (!this.readonly && event.target) {
      (event.target as HTMLInputElement).select();
    }
  }

  /**
   * Emit events
   */
  @Emit()
  private input(value: string) {
    return value;
  }
}
